import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faStumbleuponCircle, faFacebookSquare, faTwitterSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(faGoogle)
library.add(faStumbleuponCircle)
library.add(faFacebookSquare)
library.add(faTwitterSquare)
library.add(faInstagramSquare)
library.add(faLinkedin)

Vue.component('fi', FontAwesomeIcon)
